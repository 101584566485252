import React, { createContext, useContext, useState } from 'react';
import Brief from '../models/Brief';
import sampleBriefsData from '../data/briefs.json';

const BriefsContext = createContext();

export const useBriefs = () => useContext(BriefsContext);

export const BriefsProvider = ({ children }) => {
    const [briefs, setBriefs] = useState([]);

    const fetchBriefs = () => {
        const initializedBriefs = sampleBriefsData.map(brief =>
            new Brief(brief.id, brief.title, brief.officerId, brief.officerName, brief.country, brief.notes, brief.attachments));
        setBriefs(initializedBriefs);
    };

    const createBrief = (briefData) => {
        // Create a new Brief instance by directly passing the properties needed.
        const newBrief = new Brief(
            Date.now(), 
            briefData.title, 
            briefData.officerId, 
            briefData.officerName, 
            briefData.country, 
            briefData.notes, 
            briefData.attachments
        );
        setBriefs(prev => [...prev, newBrief]);
    };
    

    const editBrief = (updatedBrief) => {
        setBriefs(prev => prev.map(brief => brief.id === updatedBrief.id ? new Brief(
            updatedBrief.id,
            updatedBrief.title,
            updatedBrief.officerId,
            updatedBrief.officerName,
            updatedBrief.country,
            updatedBrief.notes,
            updatedBrief.attachments
        ) : brief));
    };
    

    const deleteBrief = (briefId) => {
        setBriefs(prev => prev.filter(brief => brief.id !== briefId));
    };

    return (
        <BriefsContext.Provider value={{ briefs, fetchBriefs, createBrief, editBrief, deleteBrief }}>
            {children}
        </BriefsContext.Provider>
    );
};

export default BriefsProvider;
