import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormField from './FormField';

const Form = ({ fields, onSubmit, initialValues }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: initialValues
    });

    // React Hook Form's reset to set form values when initialValues change
    useEffect(() => {
        reset(initialValues);
    }, [initialValues, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {fields.map((field, index) => (
                <FormField
                    key={index}
                    {...field}
                    register={register}
                    error={errors[field.name]}
                />
            ))}
            <button type="submit" className="btn btn-success">Submit</button>
        </form>
    );
};

export default Form;
