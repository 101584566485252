import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBriefs } from '../../context/BriefsContext';
import DataTable from '../../components/DataTable/DataTable';
import ModelView from '../../components/ModelView';

function AllBriefs() {
    const { briefs } = useBriefs();
    const navigate = useNavigate();
    const sortedBriefs = useMemo(() => briefs.sort((a, b) => b.id - a.id), [briefs]);
    const [selectedBrief, setSelectedBrief] = useState(null);


    const columns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Country', accessor: 'country' },
        { Header: 'Officer Name', accessor: 'officerName' },
    ];

    const viewColumns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Country', accessor: 'country' },
        { Header: 'Officer Name', accessor: 'officerName' },
        { Header: 'Attachments', accessor: 'attachments' },
    ];

    const handleRowSelect = (row) => {
        setSelectedBrief(row);
    };

    // Function to handle the edit action
    const handleEditAction = (row) => {
        navigate(`/briefs/edit/${row.id}`, { state: { brief: row } });
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-9'>
                    <DataTable
                        data={sortedBriefs}
                        columns={columns}
                        viewAction={handleRowSelect}
                        editAction={handleEditAction}

                    />
                </div>
                {selectedBrief && (
                    <div className='col-3'>
                        <h3>Activity Details</h3>
                        <ModelView data={selectedBrief} fieldsConfig={viewColumns} />
                    </div>
                )}
            </div>
            
        </div>
    );
}

export default AllBriefs;
