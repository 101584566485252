import './logo.css'
import logo from '../../assets/app-assets/images/logo/mofa.png'

function Logo({src = logo, alt}) {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <img className='mx-auto logo' src={src} alt={alt} />
        </div>
    )
}

export default Logo