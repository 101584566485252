import React from 'react';
import Wrapper from '../components/Layout/Wrapper';
import UserCard from '../components/UserCard';
import ContentBox from '../components/ContentBox';

const divisions = [
    {
        title: 'Divison One',
        footer: 'View Officers',
        footerLink: '/officers',
        list: [
            "East and central Africa + EAS",
            "ECCAS"
        ]
    },
    {
        title: 'Divison Two',
        footer: 'View Officers',
        footerLink: '/officers',
        list: [
            "Southern Africa Division + ICGLR",
            "AU",
            "NEPAD"
        ]
    },
    {
        title: 'Divison Three',
        footer: 'View Officers',
        footerLink: '/officers',
        list: [
            "North and West Africa + AMU",
            "CEN-SAD",
            "ECOWAS"
        ]
    },
    
];

function Divisions() {
    return (
        <Wrapper pageTitle={"Divisions"} links={[{url: "/", title: "Home"}]}>
            <div className='container'>
                <div className="d-flex flex-wrap justify-content-center">
                    {divisions.map((division, index) => (
                       <div key={index} className='col-xl-4 col-md-6 col-12'>
                            <ContentBox title={division.title} footerContent={division.footer} footerLink={division.footerLink}>
                                <ul className='list-group list-group-flush'>
                                    {division.list.map((item, index) => {
                                        return (<li key={index} className='list-group-item'>{item}</li>)
                                    })}
                                </ul>
                            </ContentBox>
                       </div>
                        
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}

export default Divisions;
