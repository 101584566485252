import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Layout/Wrapper';
import { Outlet, useLocation } from 'react-router-dom';
import Notification from '../../components/Notification';

function ActivitiesWrapper() {
    const location = useLocation();
    const notificationProps = location.state?.notification;
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (notificationProps && notificationProps.show) {
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 3000); // Auto-close after 3 seconds
        }
    }, [notificationProps]);

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    return (
        <Wrapper 
            pageTitle={"Activities"} 
            links={[{ url: "/", title: "Home" }]} 
            withCreateButton={true} 
            slug='activity' 
            createLink="/activities/create"
        >
            {showNotification && notificationProps && (
                <Notification message={notificationProps.message} type={notificationProps.type} onClose={handleCloseNotification} />
            )}
            <Outlet />
        </Wrapper>
    );
}

export default ActivitiesWrapper;
