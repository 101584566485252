class Officer {
    constructor(id, name, jobTitle, departmentId, profile, gender, dateOfBirth, NIN, dateJoined, address, phoneNumber, email, password) {
        this.id = id;
        this.name = name;
        this.jobTitle = jobTitle;
        this.departmentId = departmentId;
        this.profile = profile;
        this.gender = gender;
        this.dateOfBirth = dateOfBirth;
        this.NIN = NIN;
        this.dateJoined = dateJoined;
        this.address = address;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.password = password;
    }
}

export default Officer;
