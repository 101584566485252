import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from '../../components/Form/Form';
import { useBriefs } from '../../context/BriefsContext';

const formFields = [
    {
        name: 'title',
        label: 'Title',
        type: 'text',
        placeholder: 'Enter the brief title',
        validation: { required: 'Title is required' }
    },
    {
        name: 'officerId',
        label: 'Officer ID',
        type: 'number',
        placeholder: 'Enter the officer ID',
        validation: { required: 'Officer ID is required' }
    },
    {
        name: 'country',
        label: 'Country',
        type: 'select',
        options: [
            { value: 'KE', label: 'Kenya' },
            { value: 'RW', label: 'Rwanda' },
            { value: 'UG', label: 'Uganda' },
            // Add other East African countries
        ],
        validation: { required: 'Country is required' }
    },
    {
        name: 'notes',
        label: 'Notes',
        type: 'textarea',
        placeholder: 'Enter brief notes'
    },
    {
        name: 'attachments',
        label: 'Attachments',
        type: 'file'
    }
];

function BriefForm() {
    const { createBrief, editBrief } = useBriefs();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        officerId: '',
        country: '',
        notes: '',
        attachments: null
    });

    useEffect(() => {
        if (location.state?.brief) {
            setFormData(location.state.brief);
        }
    }, [location.state]);

    const handleFormSubmit = (data) => {
        if (formData.id) {
            editBrief({...formData, ...data});
            navigate('/briefs', { state: { notification: { show: true, message: 'Brief updated successfully!', type: 'success' } } });
        } else {
            createBrief(data);
            navigate('/briefs', { state: { notification: { show: true, message: 'Brief created successfully!', type: 'success' } } });
        }
    };

    return (
        <div className='container'>
            <h1>{formData.id ? 'Edit Brief' : 'Create Brief'}</h1>
            <Form fields={formFields} initialValues={formData} onSubmit={handleFormSubmit} />
        </div>
    );
}

export default BriefForm;
