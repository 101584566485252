import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from '../../components/Form/Form';
import { useMeetings } from '../../context/MeetingsContext';

const formFields = [
    {
        name: 'title',
        label: 'Meeting Title',
        type: 'text',
        placeholder: 'Enter the meeting title',
        validation: { required: 'Meeting title is required' }
    },
    {
        name: 'chairedBy',
        label: 'Chaired By',
        type: 'text',
        placeholder: 'Enter the name of the chairperson',
        validation: { required: 'Chairperson name is required' }
    },
    {
        name: 'dateOfMeeting',
        label: 'Date of Meeting',
        type: 'date',
        validation: { required: 'Meeting date is required' }
    },
    {
        name: 'notes',
        label: 'Notes/Minutes',
        type: 'textarea',
        placeholder: 'Enter the notes or minutes of the meeting'
    },
    {
        name: 'attachments',
        label: 'Attachments',
        type: 'file',
        validation: { required: false } // Attachments are optional
    }
];

function MeetingForm() {
    const { createMeeting, editMeeting } = useMeetings();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        chairedBy: '',
        dateOfMeeting: '',
        notes: '',
        attachments: null
    });

    // Check if there's initial data for editing
    useEffect(() => {
        if (location.state && location.state.meeting) {
            setFormData(location.state.meeting);
        }
    }, [location.state]);

    const handleFormSubmit = (data) => {
        if (formData.id) {
            editMeeting({ ...formData, ...data });
            navigate('/meetings', { state: { notification: { show: true, message: 'Meeting updated successfully!', type: 'success' } } });
        } else {
            createMeeting(data);
            navigate('/meetings', { state: { notification: { show: true, message: 'Meeting created successfully!', type: 'success' } } });
        }
    };

    return (
        <div className='container'>
            <div className='col-md-9'>
                <h1>{formData.id ? 'Edit Meeting' : 'Create Meeting'}</h1>
                <Form fields={formFields} initialValues={formData} onSubmit={handleFormSubmit} />
            </div>
        </div>
    );
}

export default MeetingForm;
