import React from 'react';
import Wrapper from '../components/Layout/Wrapper';
import UserCard from '../components/UserCard';

const leadershipPositions = [
    {
        name: 'Mrs. Nabirye Zainab',
        role: 'Secretariat',
        image: 'https://via.placeholder.com/150',
        socialLinks: []
    },
    {
        name: 'Mr Kasozi Najib',
        role: 'Personal assistant',
        image: 'https://via.placeholder.com/150',
        socialLinks: []
    },
    {
        name: 'Mr David Aniku',
        role: 'Office Messenger',
        image: 'https://via.placeholder.com/150',
        socialLinks: []
    },
    
];

function Secretariat() {
    return (
        <Wrapper pageTitle={"Secretariat"} links={[{url: "/", title: "Home"}, {url: "/admin", title: "Admin"}]}>
            <div className='container'>
                <div className="d-flex flex-wrap justify-content-center">
                    {leadershipPositions.map((position, index) => (
                       <div key={index} className='col-xl-4 col-md-6 col-12'>
                            <UserCard user={position} />
                       </div>
                        
                    ))}
                </div>
            </div>
        </Wrapper>
    );
}

export default Secretariat;
