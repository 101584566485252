function AuthLayout({children}) {

    return (
        <div className="app-content container center-layout mt-2">
            <div className="content-wrapper">
                {children}
            </div>
        </div>
    )

}

export default AuthLayout