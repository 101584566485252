class Activity {
    constructor(id, title, description, startTime, endTime, isOpen, location, invitations) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.startTime = startTime;
        this.endTime = endTime;
        this.isOpen = isOpen;
        this.location = location;
        this.invitations = invitations; // Array of objects containing officerId and response
    }
}

export default Activity;