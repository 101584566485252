class Brief {
    constructor(id, title, officerId, officerName, country, notes, attachments) {
        this.id = id;
        this.title = title;
        this.officerId = officerId;
        this.officerName = officerName;
        this.country = country;
        this.notes = notes;
        this.attachments = attachments;
    }
}

export default Brief;
