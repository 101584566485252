
import AppNavigation from './components/Navigation/AppNavigation';
import { ActivitiesProvider } from './context/Activities';
import { AuthProvider } from './context/AuthContext';
import BriefsProvider from './context/BriefsContext';
import { DepartmentsProvider } from './context/DepartmentsContext';
import { MeetingsProvider } from './context/MeetingsContext';
import OfficersProvider from './context/OfficersContext';
import { TasksProvider } from './context/TasksContext';

function App() {
  return (
    <AuthProvider>
      <DepartmentsProvider>
        <OfficersProvider>
          <ActivitiesProvider>
            <MeetingsProvider>
              <BriefsProvider>
              <TasksProvider>
                <AppNavigation />

              </TasksProvider>
              </BriefsProvider>
            </MeetingsProvider>
          </ActivitiesProvider>
        </OfficersProvider>
      </DepartmentsProvider>
    </AuthProvider>
    
  );
}

export default App;
