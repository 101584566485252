import { Route, BrowserRouter, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import AdminLayout from "../../pages/AdminLayout";
import AllOfficers from "../../pages/Officers/all";
import OfficersWrapper from "../../pages/Officers";
import OfficerForm from "../../pages/Officers/OfficerForm";
import ActivitiesWrapper from "../../pages/Activities";
import AllActivities from "../../pages/Activities/all";
import ActivityForm from "../../pages/Activities/ActivitiesForm";
import MeetingsWrapper from "../../pages/Meetings";
import AllMeetings from "../../pages/Meetings/all";
import MeetingForm from "../../pages/Meetings/MeetingForm";
import Leadership from "../../pages/Leadership";
import Secretariat from "../../pages/Secretariat";
import AllBriefs from "../../pages/Briefs/all";
import BriefsWrapper from "../../pages/Briefs";
import BriefForm from "../../pages/Briefs/BriefForm";
import TasksWrapper from "../../pages/Tasks";
import AllTasks from "../../pages/Tasks/all";
import TaskForm from "../../pages/Tasks/TaskForm";
import Divisions from "../../pages/Divisions";

function AppNavigation() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path="/divisions" element={<ProtectedRoute><Divisions /></ProtectedRoute>} />

                
                <Route path="/activities" element={<ProtectedRoute><ActivitiesWrapper /></ProtectedRoute>}>
                    <Route index element={<AllActivities />} />
                    <Route path="create" element={<ActivityForm />} />
                    <Route path="edit" element={<ActivityForm />} />
                </Route>

                <Route path="/meetings" element={<ProtectedRoute><MeetingsWrapper /></ProtectedRoute>}>
                    <Route index element={<AllMeetings />} />
                    <Route path="create" element={<MeetingForm />} />
                    <Route path="edit" element={<MeetingForm />} />
                </Route>
                
                <Route path="/tasks" element={<ProtectedRoute><TasksWrapper /></ProtectedRoute>}>
                    <Route index element={<AllTasks />} />
                    <Route path="create" element={<TaskForm />} />
                    <Route path="edit" element={<TaskForm />} />
                </Route>
                
                <Route path="/officers" element={<ProtectedRoute><OfficersWrapper /></ProtectedRoute>}>
                    <Route index element={<AllOfficers />} />
                    <Route path="create" element={<OfficerForm />} />
                    <Route path="edit" element={<OfficerForm />} />
                </Route>

                <Route path="/briefs" element={<ProtectedRoute><BriefsWrapper /></ProtectedRoute>}>
                    <Route index element={<AllBriefs />} />
                    <Route path="create" element={<BriefForm />} />
                    <Route path="edit/:id" element={<BriefForm />} />
                </Route>

                {/* <Route path="/officers/create" element={<ProtectedRoute><CreateOfficer /></ProtectedRoute>} />
                <Route path="/officers/edit/:id" element={<ProtectedRoute><EditOfficer /></ProtectedRoute>} /> */}

                <Route path="/admin" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
                    <Route path="leadership" element={<Leadership />} />
                    <Route path="secretariat" element={<Secretariat />} />
                    {/* <Route path="departments" element={<DepartmentWrapper />}>
                        <Route index element={<AllDepartments />} />
                        <Route path="create" element={<DepartmentForm />} />
                        <Route path="edit" element={<DepartmentForm />} />
                    </Route> */}
                    
                </Route>
                
                
            </Routes>
        </BrowserRouter>
    );
}

export default AppNavigation;