function UserCard ({user}) {
    return (
            <div className="card mr-3 mb-3">
                <div className="text-center">
                    <div className="card-body">
                        <img src={user.image} className="rounded-circle height-150" alt="Card image" />
                    </div>
                    <div className="card-body">
                        <h4 className="card-title">{user.name}</h4>
                        <h6 className="card-subtitle text-muted">{user.role}</h6>
                    </div>
                    <div className="text-center">
                        {user.socialLinks.map((link, index) => (
                            <a key={index} href={link.url} className="btn btn-social-icon mr-1 mb-1 btn-outline-facebook"><span className={link.icon}></span></a>
                        ))}
                    </div>
                </div>
            </div>
    )
}

export default UserCard