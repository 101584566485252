import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./navbar.css"

function MenuItem({ item }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <li className={`nav-item ${item.submenu ? 'dropdown' : ''}  position-relative d-flex align-items-center justify-content-center`}>
            <Link
                className={`nav-link ${item.submenu ? 'dropdown-toggle' : ''}`}
                to={item.link}
                onClick={item.submenu ? toggleDropdown : null}
            >
                <span>{item.title}</span>
            </Link>
            {item.submenu && isOpen && (
                <ul className="dropdown m-0 p-0 position-absolute bg-white shadow-sm top-100 start-0 z-3" onFocus={() => {console.log("away")}}>
                    {item.submenu.map((subItem, index) => (
                        <li key={index}>
                            <Link className="dropdown-item px-3 w-100" to={subItem.link}>
                                {subItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
}

export default MenuItem;
