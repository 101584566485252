import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../components/Layout/Auth';
import logo from '../assets/app-assets/images/logo/mofa.png'
import Logo from '../components/Logo/Logo';
import { useAuth } from '../context/AuthContext';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (username === 'admin' && password === 'password') {
        await login();
        navigate('/home'); // Navigate to the home page on successful login
    } else {
      alert('Invalid credentials'); // Show an error message for invalid credentials
    }
  };

  return (
    <AuthLayout>
        <div className="content-body">
            <section className="flexbox-container">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="col-md-6 col-10 p-0">
                        <div className="card border-grey border-lighten-3 m-0">
                            <div className="card-header border-0">
                                <div className="card-title text-center">
                                    <div className="p-1"><Logo src={logo} alt={"mofa logo"}></Logo></div>
                                </div>
                                <h1 className='text-center'>RPS DEPARTMENT</h1>
                                <h6 className="card-subtitle text-muted text-center font-small-3 pt-2"><span>The Ministry of Foreign Affairs (MoFA) is a cabinet-level government ministry responsible for the implementation and management of Uganda's foreign policy.</span></h6>
                            </div>
                            <div className="card-content">
                                <div className="card-body">
                                    <form className="form-horizontal form-simple" action="index.html" noValidate onSubmit={handleSubmit}>
                                        <fieldset className="form-group position-relative has-icon-left mb-0">
                                            <input type="text" className="form-control form-control-lg input-lg" id="user-name" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Your Username" required />
                                            <div className="form-control-position">
                                                <i className="ft-user"></i>
                                            </div>
                                        </fieldset>
                                        <fieldset className="form-group position-relative has-icon-left">
                                            <input type="password" className="form-control form-control-lg input-lg" id="user-password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" required />
                                            <div className="form-control-position">
                                                <i className="fa fa-key"></i>
                                            </div>
                                        </fieldset>
                                        <div className="form-group row">
                                            <div className="col-md-6 col-12"><a href="recover-password.html" className="card-link">Forgot Password?</a></div>
                                        </div>
                                        <button type="submit" className="btn btn-info btn-lg btn-block"><i className="ft-unlock"></i> Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       
    </AuthLayout>
  );
}

export default Login;
