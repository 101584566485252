import React, { createContext, useContext, useState } from 'react';
import Activity from '../models/Activity';
import sampleActivitiesData from '../data/activities.json'; // Ensure this import points to the correct file location

const ActivitiesContext = createContext();

export const useActivities = () => useContext(ActivitiesContext);

export const ActivitiesProvider = ({ children }) => {
    const [activities, setActivities] = useState([]);

    const fetchActivities = () => {
        const loadedActivities = sampleActivitiesData.map(act => 
            new Activity(
                act.id, 
                act.title, 
                act.description, 
                act.startTime, 
                act.endTime, 
                act.isOpen, 
                act.location, 
                act.invitations
            )
        );
        setActivities(loadedActivities);
    };

    const createActivity = (activityData) => {
        const newActivity = new Activity(
            Date.now(), 
            activityData.title, 
            activityData.description, 
            activityData.startTime, 
            activityData.endTime, 
            activityData.isOpen, 
            activityData.location, 
            []
        );
        setActivities(prevActivities => [...prevActivities, newActivity]);
    };

    const editActivity = (id, updatedData) => {
        setActivities(prevActivities =>
            prevActivities.map(activity => 
                activity.id === id ? 
                new Activity(id, updatedData.title, updatedData.description, updatedData.startTime, updatedData.endTime, updatedData.isOpen, updatedData.location, updatedData.invitations) : 
                activity
            )
        );
    };

    const deleteActivity = (id) => {
        setActivities(prevActivities => prevActivities.filter(activity => activity.id !== id));
    };

    return (
        <ActivitiesContext.Provider value={{ activities, fetchActivities, createActivity, editActivity, deleteActivity }}>
            {children}
        </ActivitiesContext.Provider>
    );
};
