import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/app-assets/css/vendors.css'
import './assets/app-assets/css/app.css'
import './assets/app-assets/vendors/css/forms/icheck/icheck.css'
import './assets/app-assets/vendors/css/forms/icheck/custom.css'
import './assets/app-assets/css/app.css'
import './assets/app-assets/css/core/menu/menu-types/horizontal-menu.css'
import './assets/app-assets/css/core/colors/palette-gradient.css'
import './assets/app-assets/css/pages/login-register.css'
import './assets/assets/css/style.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
