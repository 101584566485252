import React, { useEffect } from "react";
import { useDepartments } from "../../context/DepartmentsContext";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { useActivities } from "../../context/Activities";
import { useMeetings } from "../../context/MeetingsContext";
import { useBriefs } from "../../context/BriefsContext";
import { useTasks } from "../../context/TasksContext";

function Wrapper({links, pageTitle, children, withCreateButton, slug='item', createLink=''}) {

    const { fetchDepartments } = useDepartments();
    const { fetchActivities } = useActivities();
    const { fetchMeetings } = useMeetings();
    const { fetchBriefs } = useBriefs();
    const { fetchTasks } = useTasks()

    useEffect(() => {
        fetchDepartments();
        fetchActivities();
        fetchMeetings();
        fetchBriefs();
        fetchTasks();
    }, []);


    return (
        <div className="container-fluid">
            <Navbar />
            <div className="row bg-light d-flex flex-grow-1" style={{minHeight: '100vh'}}>
                <div className="app-content container center-layout mt-2">
                    <div className="content-wrapper">
                        <div className="content-header row">
                            <div className="content-header-left col-md-6 col-12 mb-2">
                                <div className="row breadcrumbs-top">
                                    <div className="breadcrumb-wrapper col-12">
                                        <ol className="breadcrumb">
                                            {links?.map((link, index) => {
                                                return (
                                                    <li key={index} className="breadcrumb-item"><Link to={link.url}>{link.title}</Link></li>
                                            
                                                )
                                            })}
                                            <li className="breadcrumb-item active">{pageTitle}
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <h3 className="content-header-title mb-0">{pageTitle}</h3>
                            </div>
                            <div className="content-header-right col-md-6 col-12">
                                {withCreateButton && (
                                    <div className="btn-group float-md-right">   
                                        <Link to={createLink} className="btn btn-info round px-2" id="btnGroupDrop1" type="button"><i className="ft-plus icon-left"></i> {`Add new ${slug}`}</Link>
                                    </div>
                                )}
                                
                            </div>
                        </div>
                        <div className="content-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wrapper;