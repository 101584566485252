import React, { createContext, useContext, useState } from 'react';
import Department from '../models/Department';
import sampleDepartmentsData from '../data/departments.json';

const DepartmentsContext = createContext();

export const useDepartments = () => useContext(DepartmentsContext);

export const DepartmentsProvider = ({ children }) => {
    const [departments, setDepartments] = useState([]);

    const fetchDepartments = () => {
        // Simulate an API call
        const sampleDepartments = sampleDepartmentsData.map(dept => new Department(dept.id, dept.name, dept.headOfDepartment));
        setDepartments(sampleDepartments);
    };

    const createDepartment = (department) => {
        setDepartments((prevDepartments) => [...prevDepartments, new Department(Date.now(), department.name, department.headOfDepartment)]);
    };

    const editDepartment = (updatedDepartment) => {
        setDepartments((prevDepartments) =>
            prevDepartments.map((department) =>
                department.id === updatedDepartment.id ? new Department(updatedDepartment.id, updatedDepartment.name, updatedDepartment.headOfDepartment) : department
            )
        );
    };

    const deleteDepartment = (departmentId) => {
        setDepartments((prevDepartments) => prevDepartments.filter((department) => department.id !== departmentId));
    };

    return (
        <DepartmentsContext.Provider value={{ departments, fetchDepartments, createDepartment, editDepartment, deleteDepartment }}>
            {children}
        </DepartmentsContext.Provider>
    );
};
