import React from 'react';

function ModelView({ data, fieldsConfig }) {
    if (!data) {
        return <p>No data available</p>; // Display a message when no data is provided
    }

    return (
        <div className="model-view">
            {fieldsConfig.map((field) => (
                data[field.accessor] ? ( // Conditional rendering to check if the data contains the key
                    <div key={field.Header} className="model-view-item">
                        <strong>{field.Header}:</strong> {data[field.accessor]}
                    </div>
                ) : null
            ))}
        </div>
    );
}

export default ModelView;
