class Meeting {
    constructor(id, title, chairedBy, dateOfMeeting, notes, attachments) {
        this.id = id;
        this.title = title;
        this.chairedBy = chairedBy;
        this.dateOfMeeting = dateOfMeeting;
        this.notes = notes;
        this.attachments = attachments;
    }
}

export default Meeting;
