import React from 'react';
import Wrapper from '../components/Layout/Wrapper';
import { Outlet } from 'react-router-dom';

function AdminLayout() {
  return (
    <Outlet />
  );
}

export default AdminLayout;