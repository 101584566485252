import React from 'react';

function Notification({ message, type, onClose }) {
    if (!message) return null;

    return (
        <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
            {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}

export default Notification;
