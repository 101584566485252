import React, { useState, useEffect } from 'react';
import Form from '../../components/Form/Form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useActivities } from '../../context/Activities';

const formFields = [
    {
        name: 'title',
        label: 'Activity Title',
        type: 'text',
        placeholder: 'Enter the activity title',
        validation: { required: 'Activity title is required' }
    },
    {
        name: 'description',
        label: 'Description',
        type: 'textarea',
        placeholder: 'Describe the activity',
    },
    {
        name: 'startTime',
        label: 'Start Time',
        type: 'datetime-local',
        validation: { required: 'Start time is required' }
    },
    {
        name: 'endTime',
        label: 'End Time',
        type: 'datetime-local',
        validation: { required: 'End time is required' }
    },
    {
        name: 'location',
        label: 'Location',
        type: 'text',
        placeholder: 'Enter the location',
        validation: { required: 'Location is required' }
    },
    {
        name: 'isOpen',
        label: 'Open to All?',
        type: 'checkbox'
    }
];

function ActivityForm() {
    const { createActivity, editActivity } = useActivities();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        startTime: '',
        endTime: '',
        location: '',
        isOpen: false,
    });    

    // Check if there's initial data for editing
    useEffect(() => {
        if (location.state && location.state.activity) {
            setFormData(location.state.activity);
        }
    }, [location.state]);

    const handleFormSubmit = (data) => {
        const updatedData = {
            ...formData,
            ...data,
            isOpen: data.isOpen === 'on' // Handle checkbox value conversion
        };
        if (formData.id) {
            editActivity(updatedData);
            navigate('/activities', { state: { notification: { show: true, message: 'Activity updated successfully!', type: 'success' } } });
        } else {
            createActivity(updatedData);
            navigate('/activities', { state: { notification: { show: true, message: 'Activity created successfully!', type: 'success' } } });
        }
    };

    return (
        <div className='container'>
            <div className='col-md-9'>
                <h1>{formData.id ? 'Edit' : 'Create'} Activity</h1>
                <Form fields={formFields} initialValues={formData} onSubmit={handleFormSubmit} />
            </div>
        </div>
    );
}

export default ActivityForm;
