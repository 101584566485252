import React, { useMemo, useState } from 'react';
import { useOfficers } from '../../context/OfficersContext';
import ModelView from '../../components/ModelView';
import DataTable from '../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';

function AllOfficers() {
    const { officers } = useOfficers();
    const [selectedOfficer, setSelectedOfficer] = useState(null);
    const navigate = useNavigate();

    // Sort officers by ID in descending order
    const sortedOfficers = useMemo(() => {
        return [...officers].sort((a, b) => b.id - a.id);
    }, [officers]);

    const columns = [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Job Title', accessor: 'jobTitle' },
        { Header: 'Date Joined', accessor: 'dateJoined' },
        { Header: 'Phone Number', accessor: 'phoneNumber' },
    ];

    const viewColumns = [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Job Title', accessor: 'jobTitle' },
        { Header: 'ID', accessor: 'id' },
        { Header: 'Profile', accessor: 'profile' },
        { Header: 'Gender', accessor: 'gender' },
        { Header: 'Date of Birth', accessor: 'dateOfBirth' },
        { Header: 'NIN', accessor: 'NIN' },
        { Header: 'Date Joined', accessor: 'dateJoined' },
        { Header: 'Address', accessor: 'address' },
        { Header: 'Phone Number', accessor: 'phoneNumber' },
        { Header: 'Email', accessor: 'email' }
    ];

    const handleRowSelect = (row) => {
        navigate('/officers/edit', { state: { officer: row } });
    };

    // Function to handle the edit action
    const handleEditAction = (row) => {
        navigate('/officers/edit', { state: { officer: row } });
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <DataTable
                        data={sortedOfficers}
                        columns={columns}
                        viewAction={handleRowSelect}
                    />
                </div>
                {selectedOfficer && (
                    <div className='col-4'>
                        <h3>Officer Details</h3>
                        <ModelView data={selectedOfficer} fieldsConfig={viewColumns} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AllOfficers;
