// src/models/Task.js
export default class Task {
    constructor(id, title, description, dueDate, status, officerId, assignedBy) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.dueDate = dueDate;
        this.status = status;
        this.officerId = officerId;
        this.assignedBy = assignedBy;
    }
}