import React, { useMemo } from 'react';
import { useTasks } from '../../context/TasksContext';
import DataTable from '../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';

function AllTasks() {
    const { tasks } = useTasks();
    const navigate = useNavigate();

    const columns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Due Date', accessor: 'dueDate' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Assigned By', accessor: 'assignedBy' },
        { Header: 'Actions', accessor: 'actions' }
    ];

    const handleEditAction = (task) => {
        navigate(`/tasks/edit`, { state: { task } });
    };

    const sortedTasks = useMemo(() => tasks.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate)), [tasks]);

    return (
        <DataTable data={sortedTasks} columns={columns} editAction={handleEditAction} />
    );
}

export default AllTasks;
