import React, { createContext, useContext, useState } from 'react';
import Task from '../models/Task';
import sampleTasksData from '../data/tasks.json';

const TasksContext = createContext();

export const useTasks = () => useContext(TasksContext);

export const TasksProvider = ({ children }) => {
    const [tasks, setTasks] = useState([]);

    const fetchTasks = () => {
        const initializedTasks = sampleTasksData.map(task => 
            new Task(task.id, task.title, task.description, task.dueDate, task.status, task.officerId, task.assignedBy));
        setTasks(initializedTasks);
    };

    const createTask = (taskData) => {
        const newTask = new Task(Date.now(), taskData.title, taskData.description, taskData.dueDate, taskData.status, taskData.officerId, taskData.assignedBy);
        setTasks(prev => [...prev, newTask]);
    };

    const editTask = (updatedTask) => {
        setTasks(prev => prev.map(task => task.id === updatedTask.id ? new Task(updatedTask.id, updatedTask.title, updatedTask.description, updatedTask.dueDate, updatedTask.status, updatedTask.officerId, updatedTask.assignedBy) : task));
    };

    const deleteTask = (taskId) => {
        setTasks(prev => prev.filter(task => task.id !== taskId));
    };

    return (
        <TasksContext.Provider value={{ tasks, fetchTasks, createTask, editTask, deleteTask }}>
            {children}
        </TasksContext.Provider>
    );
};
