import React, { useState, useMemo } from 'react';

function DataTable({ data, columns, viewAction, editAction }) {
    const [filter, setFilter] = useState('');

    const filteredData = useMemo(() => {
        if (!filter) return data;
        return data.filter(row =>
            columns.some(column =>
                row[column.accessor].toString().toLowerCase().includes(filter.toLowerCase())
            )
        );
    }, [data, columns, filter]);

    return (
        <>
            <input
                type="text"
                className="form-control mb-3"
                placeholder="Search..."
                onChange={e => setFilter(e.target.value)}
            />
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column.Header}</th>
                            ))}
                            {(viewAction || editAction) && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex} className='bg-white'>
                                {columns.map((column, colIndex) => (
                                    <td key={colIndex}>{row[column.accessor]}</td>
                                ))}
                                {(viewAction || editAction) && (
                                    <td>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            {viewAction && (
                                                <button
                                                    onClick={() => viewAction(row)}
                                                    className="btn btn-primary btn-sm mr-2">
                                                        <i className='fa fa-eye'></i>
                                                        &nbsp;View
                                                </button>
                                            )}
                                            {editAction && (
                                                <button
                                                    onClick={() => editAction(row)}
                                                    className="btn btn-secondary btn-sm">
                                                    <i className='fa fa-edit'></i>
                                                        &nbsp;Edit
                                                </button>
                                            )}
                                        </div>
                                        
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default DataTable;
