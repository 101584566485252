import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from '../../components/Form/Form';
import { useTasks } from '../../context/TasksContext';

const formFields = [
    {
        name: 'title',
        label: 'Task Title',
        type: 'text',
        placeholder: 'Enter task title',
        validation: { required: 'Task title is required' }
    },
    {
        name: 'description',
        label: 'Description',
        type: 'textarea',
        placeholder: 'Describe the task',
    },
    {
        name: 'dueDate',
        label: 'Due Date',
        type: 'date',
        validation: { required: 'Due date is required' }
    },
    {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
            { value: '', label: 'Select status' },
            { value: 'Pending', label: 'Pending' },
            { value: 'Completed', label: 'Completed' }
        ],
        validation: { required: 'Status is required' }
    },
    {
        name: 'assignedBy',
        label: 'Assigned By',
        type: 'text',
        placeholder: 'Enter the ID of the officer assigning the task',
    }
];

function TaskForm() {
    const { createTask, editTask } = useTasks();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        dueDate: '',
        status: '',
        assignedBy: ''
    });

    useEffect(() => {
        // Pre-fill form when editing an existing task
        if (location.state?.task) {
            setFormData(location.state.task);
        }
    }, [location.state]);

    const handleFormSubmit = (data) => {
        if (formData.id) {
            // Call the editTask function if we're editing an existing task
            editTask({...formData, ...data});
        } else {
            // Call the createTask function if we're creating a new task
            createTask(data);
        }
        navigate('/tasks'); // Navigate back to the tasks list after submission
    };

    return (
        <div className='container'>
            <h1>{formData.id ? 'Edit Task' : 'Create Task'}</h1>
            <Form fields={formFields} initialValues={formData} onSubmit={handleFormSubmit} />
        </div>
    );
}

export default TaskForm;
