import React from 'react';

const FormField = ({ name, label, type, placeholder, register, validation, error, options }) => {
    return (
        <div className="form-group d-flex flex-column">
            <label htmlFor={name}>{label}</label>
            {type === 'select' ? (
                <select 
                    id={name}
                    name={name}
                    {...register(name, validation)}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                >
                    <option value="">Select...</option>
                    {options && options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
            ) : type === 'checkbox' ? (
                <input
                    id={name}
                    name={name}
                    type={type}
                    {...register(name, {
                        ...validation,
                        valueAsBoolean: true // Ensure the checkbox value is treated as a boolean
                    })}
                    className={`form-check-input ${error ? 'is-invalid' : ''}`}
                />
            ) : type === 'textarea' ? (
                <textarea
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    {...register(name, validation)}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    rows="10"
                ></textarea>
            ) : (
                <input
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    {...register(name, validation)}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                />
            )}
            {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
    );
};

export default FormField;
