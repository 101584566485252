import React, { useMemo, useState } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import ModelView from '../../components/ModelView';
import { useNavigate } from 'react-router-dom';
import { useMeetings } from '../../context/MeetingsContext';

function AllMeetings() {
    const { meetings } = useMeetings();
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const navigate = useNavigate();

    // Sort meetings by ID in descending order
    const sortedMeetings = useMemo(() => {
        return [...meetings].sort((a, b) => b.id - a.id);
    }, [meetings]);

    const columns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Chaired By', accessor: 'chairedBy' },
        { Header: 'Date of Meeting', accessor: 'dateOfMeeting' },
    ];

    const viewColumns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Chaired By', accessor: 'chairedBy' },
        { Header: 'Date of Meeting', accessor: 'dateOfMeeting' },
        { Header: 'Notes/Minutes', accessor: 'notes' },
        { Header: 'Attachments', accessor: 'attachments' },
    ];

    const handleRowSelect = (row) => {
        setSelectedMeeting(row);
    };

    // Function to handle the edit action
    const handleEditAction = (row) => {
        navigate('/meetings/edit', { state: { meeting: row } });
    };

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-9'>
                    <DataTable
                        data={sortedMeetings}
                        columns={columns}
                        viewAction={handleRowSelect}
                        editAction={handleEditAction}
                    />
                </div>
                {selectedMeeting && (
                    <div className='col-3'>
                        <h3>Meeting Details</h3>
                        <ModelView data={selectedMeeting} fieldsConfig={viewColumns} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AllMeetings;
