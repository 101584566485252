import { Link } from "react-router-dom"

function ContentBox ({title, children, footerContent, footerLink}) {
    return (
            <div className="card mr-3 mb-3">
                <div className="text-center">
                    <div class="card-header">
                        <h5 class="card-title">{title}</h5>
                    </div>
                    <div class="card-body">
                        <p class="card-text">{children}</p>
                    </div>
                    <div class="card-footer text-muted">
                        {<Link to={footerLink}>{footerContent}</Link>}
                    </div>
                </div>
                
            </div>
    )
}

export default ContentBox