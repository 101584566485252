import React, { createContext, useContext, useState, useEffect } from 'react';
import Officer from '../models/Officer';
import sampleOfficers from '../data/officers.json';  // Ensure the path matches where you store your JSON

const OfficersContext = createContext();

export const useOfficers = () => useContext(OfficersContext);

export const OfficersProvider = ({ children }) => {
    const [officers, setOfficers] = useState([]);

    useEffect(() => {
        const loadedOfficers = sampleOfficers.map(officer =>
            new Officer(officer.id, officer.name, officer.jobTitle, officer.departmentId, officer.profile, officer.gender, officer.dateOfBirth, officer.NIN, officer.dateJoined, officer.address, officer.phoneNumber, officer.email, officer.password)
        );
        setOfficers(loadedOfficers);
    }, []);

    const createOfficer = (officerData) => {
        const newOfficer = new Officer(Date.now(), ...Object.values(officerData));
        setOfficers(prevOfficers => [...prevOfficers, newOfficer]);
    };

    const editOfficer = (updatedOfficer) => {
        setOfficers(prevOfficers =>
            prevOfficers.map(officer =>
                officer.id === updatedOfficer.id ? new Officer(updatedOfficer.id, ...Object.values(updatedOfficer)) : officer
            )
        );
    };

    const deleteOfficer = (officerId) => {
        setOfficers(prevOfficers => prevOfficers.filter(officer => officer.id !== officerId));
    };

    return (
        <OfficersContext.Provider value={{
            officers,
            createOfficer,
            editOfficer,
            deleteOfficer
        }}>
            {children}
        </OfficersContext.Provider>
    );
};

export default OfficersProvider;
