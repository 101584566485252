// src/menuItems.js
const menuItems = [
    {
        title: "Home",
        icon: "icon-home",
        link: "/home"
    },
    {
        title: "Admin",
        icon: "icon-bulb",
        link: "#",
        submenu: [
            {
                title: "Leadership",
                link: "/admin/leadership"
            },
            {
                title: "Secretariat",
                link: "/admin/secretariat"
            },
        ]
    },
    {
        title: "Officers",
        icon: "icon-docs",
        link: "/officers"
    },
    {
        title: "Divisons",
        icon: "icon-docs",
        link: "/divisions"
    },
    {
        title: "Activities",
        icon: "icon-docs",
        link: "/activities"
    },
    {
        title: "Briefs",
        icon: "icon-docs",
        link: "/briefs"
    },
    {
        title: "Meetings",
        icon: "icon-docs",
        link: "/meetings"
    },
    {
        title: "Tasks",
        icon: "icon-docs",
        link: "/tasks"
    },
];

export default menuItems;
