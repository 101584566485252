import React from 'react';
import Wrapper from '../components/Layout/Wrapper';

function Home() {
  return (
    <Wrapper pageTitle={"Home"}>
        <p>Welcome</p>
    </Wrapper>
  );
}

export default Home;