import React from 'react';
import Wrapper from '../../components/Layout/Wrapper';
import { Outlet } from 'react-router-dom';

function MeetingsWrapper() {
    return (
        <Wrapper
            pageTitle="Meetings"
            links={[{ url: "/", title: "Home" }]}
            withCreateButton={true}
            slug='meeting'
            createLink="/meetings/create"
        >
            <Outlet />
        </Wrapper>
    );
}

export default MeetingsWrapper;
