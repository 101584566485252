import React from 'react';
import MenuItem from './MenuItem';
import menuItems from '../../menuItems';
import Logo from '../Logo/Logo';

function Navbar() {
    return (
        <div className='container-fluid shadow-sm py-1'>
            <div className='container'>
                <nav className='d-flex justify-content-between align-items-center'>
                    <Logo />
                    <ul className="d-flex" id="main-menu-navigation">
                        {menuItems && menuItems.map((item, index) => {
                            return <MenuItem key={index} item={item} />
                        })}
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
