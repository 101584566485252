import React, { createContext, useContext, useState } from 'react';
import Meeting from '../models/Meeting';
import sampleMeetingsData from '../data/meetings.json';

const MeetingsContext = createContext();

export const useMeetings = () => useContext(MeetingsContext);

export const MeetingsProvider = ({ children }) => {
    const [meetings, setMeetings] = useState([]);

    const fetchMeetings = () => {
        const initializedMeetings = sampleMeetingsData.map(meet =>
            new Meeting(meet.id, meet.title, meet.chairedBy, meet.dateOfMeeting, meet.notes, meet.attachments));
        setMeetings(initializedMeetings);
    };

    const createMeeting = (meetingData) => {
        const newMeeting = new Meeting(Date.now(), meetingData.title, meetingData.chairedBy, meetingData.dateOfMeeting, meetingData.notes, meetingData.attachments);
        setMeetings(prev => [...prev, newMeeting]);
    };

    const editMeeting = (updatedMeeting) => {
        setMeetings(prev => prev.map(meet => meet.id === updatedMeeting.id ? 
            new Meeting(updatedMeeting.id, updatedMeeting.title, updatedMeeting.chairedBy, updatedMeeting.dateOfMeeting, updatedMeeting.notes, updatedMeeting.attachments) : meet));
    };

    const deleteMeeting = (meetingId) => {
        setMeetings(prev => prev.filter(meet => meet.id !== meetingId));
    };

    return (
        <MeetingsContext.Provider value={{ meetings, fetchMeetings, createMeeting, editMeeting, deleteMeeting }}>
            {children}
        </MeetingsContext.Provider>
    );
};
