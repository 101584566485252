import React, { useMemo, useState } from 'react';
import ModelView from '../../components/ModelView';
import DataTable from '../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { useActivities } from '../../context/Activities';

function AllActivities() {
    const { activities } = useActivities();
    const [selectedActivity, setSelectedActivity] = useState(null);
    const navigate = useNavigate();

    // Sort activities by ID in descending order
    const sortedActivities = useMemo(() => {
        return [...activities].sort((a, b) => b.id - a.id);
    }, [activities]);

    const columns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Start Time', accessor: 'startTime' },
        { Header: 'End Time', accessor: 'endTime' },
        { Header: 'Location', accessor: 'location' },
    ];

    const viewColumns = [
        { Header: 'Title', accessor: 'title' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Start Time', accessor: 'startTime' },
        { Header: 'End Time', accessor: 'endTime' },
        { Header: 'Location', accessor: 'location' },
    ];

    const handleRowSelect = (row) => {
        setSelectedActivity(row);
    };

    // Function to handle the edit action
    const handleEditAction = (row) => {
        navigate('/activities/edit', { state: { activity: row } });
    };

    console.log(activities);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-9'>
                    <DataTable
                        data={sortedActivities}
                        columns={columns}
                        viewAction={handleRowSelect}
                        editAction={handleEditAction}
                    />
                </div>
                {selectedActivity && (
                    <div className='col-3'>
                        <h3>Activity Details</h3>
                        <ModelView data={selectedActivity} fieldsConfig={viewColumns} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AllActivities;
