import React, { useState, useEffect } from 'react';
import { useOfficers } from '../../context/OfficersContext';
import Form from '../../components/Form/Form';
import { useNavigate, useLocation } from 'react-router-dom';

const formFields = [
    {
        name: 'name',
        label: 'Officer Name',
        type: 'text',
        placeholder: 'Enter the officer name',
        validation: { required: 'Officer name is required' }
    },
    {
        name: 'jobTitle',
        label: 'Job Title',
        type: 'text',
        placeholder: 'Enter the job title',
        validation: { required: 'Job title is required' }
    },
    // {
    //     name: 'departmentId',
    //     label: 'Department ID',
    //     type: 'number',
    //     placeholder: 'Enter the department ID',
    //     validation: { required: 'Department ID is required' }
    // },
    {
        name: 'profile',
        label: 'Profile',
        type: 'textarea',
        placeholder: 'Enter profile description'
    },
    {
        name: 'gender',
        label: 'Gender',
        type: 'select',
        options: [{ value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' }, { value: 'Other', label: 'Other' }],
        validation: { required: 'Gender is required' }
    },
    {
        name: 'dateOfBirth',
        label: 'Date of Birth',
        type: 'date',
        validation: { required: 'Date of birth is required' }
    },
    {
        name: 'NIN',
        label: 'National Identification Number',
        type: 'text',
        placeholder: 'Enter NIN',
        validation: { required: 'NIN is required' }
    },
    {
        name: 'dateJoined',
        label: 'Date Joined',
        type: 'date',
        validation: { required: 'Date joined is required' }
    },
    {
        name: 'address',
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address'
    },
    {
        name: 'phoneNumber',
        label: 'Phone Number',
        type: 'tel',
        placeholder: 'Enter phone number',
        validation: { required: 'Phone number is required' }
    },
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter email',
        validation: { required: 'Email is required', pattern: { value: /^\S+@\S+\.\S+$/, message: 'Entered value does not match email format' } }
    },
    {
        name: 'password',
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        validation: { required: 'Password is required' }
    },
];


function OfficerForm() {
    const { createOfficer, editOfficer } = useOfficers();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        name: '',
        jobTitle: '',
        departmentId: 1,
        profile: '',
        gender: '',
        dateOfBirth: '', // Use a date string format 'YYYY-MM-DD' for date inputs
        NIN: '',
        dateJoined: '', // Use a date string format 'YYYY-MM-DD' for date inputs
        address: '',
        phoneNumber: '',
        email: '',
        password: ''
    });    

    // Check if there's initial data for editing
    useEffect(() => {
        if (location.state && location.state.officer) {
            setFormData(location.state.officer);
        }
    }, [location.state]);

    const handleFormSubmit = (data) => {
        if (formData.id) {
            editOfficer({...formData, ...data});
            navigate('/officers', { state: { notification: { show: true, message: 'Officer updated successfully!', type: 'success' } } });
        } else {
            createOfficer(data);
            navigate('/officers', { state: { notification: { show: true, message: 'Officer created successfully!', type: 'success' } } });
        }
    };

    return (
        <div className='container'>
            <div className='col-md-9'>
                <h1>{formData.id ? 'Edit' : 'Create'} Officer</h1>
                <Form fields={formFields} initialValues={formData} onSubmit={handleFormSubmit} />
            </div>
        </div>
    );
}

export default OfficerForm;
